import {
  EDIT_RATE,
  FETCH_RATE,
  FETCH_RATE_FAILED,
  FETCH_RATE_SUCCESS
} from "../store/types";
  
  const INITIAL_STATE = {
    rate: null,
    loading: false,
    error: {
      flag: false,
      msg: null
    }
  }
  
  export const ratelistreducer = (state = INITIAL_STATE, action) => {
    // console.log("action", action);
    // console.log("type", action.type);
    // console.log("state", state);
    switch (action.type) {
      case FETCH_RATE:
        return {
          ...state,
          loading: true
        };
      case FETCH_RATE_SUCCESS:
        return {
          ...state,
          rate: action.payload,
          loading: false
        };
      case FETCH_RATE_FAILED:
        return {
          ...state,
          rate: null,
          loading: false,
          error: {
            flag: true,
            msg: action.payload
          }
        };
      case EDIT_RATE:
        return state;
      default:
        return state;
    }
  };
  