import {
  EDIT_FARE,
  FETCH_FARE,
  FETCH_FARE_FAILED,
  FETCH_FARE_SUCCESS
} from "../store/types";

const INITIAL_STATE = {
  fare: null,
  loading: false,
  error: {
    flag: false,
    msg: null
  }
}

export const farelistreducer = (state = INITIAL_STATE, action) => {
  // console.log("action", action);
  // console.log("type", action.type);
  // console.log("state", state);
  switch (action.type) {
    case FETCH_FARE:
      return {
        ...state,
        loading: true
      };
    case FETCH_FARE_SUCCESS:
      return {
        ...state,
        fare: action.payload,
        loading: false
      };
    case FETCH_FARE_FAILED:
      return {
        ...state,
        fare: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload
        }
      };
    case EDIT_FARE:
      return state;
    default:
      return state;
  }
};
