import { onValue, push, remove, set } from "firebase/database";
import { firebase } from '../config/configureFirebase';
import store from '../store/store';
import {
  EDIT_FARE,
  FETCH_FARE,
  FETCH_FARE_FAILED,
  FETCH_FARE_SUCCESS
} from "../store/types";
  
  export const fetchFare = () => (dispatch) => {
  
    const {
        fareRef
    } = firebase;
  
    dispatch({
      type: FETCH_FARE,
      payload: null
    });

    onValue(fareRef, async snapshot => {
      
      if (snapshot.val()) {
        let data = snapshot.val();
        const arr = Object.keys(data).map(i => {
          data[i].id = i;
          return data[i]
        });
        dispatch({
          type: FETCH_FARE_SUCCESS,
          payload: arr
        });
      } else {
        dispatch({
          type: FETCH_FARE_FAILED,
          payload: store.getState().languagedata.defaultLanguage.no_fare
        });
      }
    });
  };
  
  export const editFare = (fare, method) => async (dispatch) => {
  const {
    fareRef, 
    fareEditRef
  } = firebase;
  dispatch({
    type: EDIT_FARE,
    payload: { method, fare }
  });
  if (method === 'Add') {
    push(fareRef, fare);
  } else if (method === 'Delete') {
    remove(fareEditRef(fare.id));
  } else {
    set(fareEditRef(fare.id), fare);
  }
}

